'use client'

import { type MenuProps, Menu } from 'antd'

import styles from './headerMenu.module.scss'

export default function HeaderMenu({ items }: { items: MenuProps['items'] }) {
  return (
    <Menu
      className={`${styles.container}`}
      items={items}
      mode='horizontal'
      selectedKeys={[]}
    ></Menu>
  )
}
