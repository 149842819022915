'use client'

import { useEffect, useState } from 'react'
import LayoutFooter from './components/LayoutFooter'
import LayoutHeader from './components/LayoutHeader'
import styles from './layout.module.scss'

export default function PublicZoneLayout({
  children,
}: React.PropsWithChildren) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    isClient && (
      <div className={styles.container}>
        <LayoutHeader />
        <main className={styles.main}>
          <section className={styles.content}>{children}</section>
          <LayoutFooter />
        </main>
      </div>
    )
  )
}
