'use client'

import { Icon } from '@/components/Icon'

import CircutorLargeIcon from '@/public/img/icon-large.svg'
import HeaderMenu from '@/app/components/HeaderMenu'
import { LANGUAGES } from '@/constants/constants'

import { useTranslation } from 'react-i18next'
import useIsMobile from '@/hooks/useIsMobile'

import styles from './LayoutHeader.module.scss'

export default function LayoutHeader() {
  const { i18n } = useTranslation()
  const isMobile = useIsMobile()

  const currentLanguage = ['es', 'en', 'fr', 'pt'].includes(
    i18n.language.slice(0, 2).toLowerCase(),
  )
    ? i18n.language.slice(0, 2).toLowerCase()
    : 'es'

  const menuItems = [
    {
      key: currentLanguage,
      label: (
        <div className={styles.mycircutorLink}>
          {currentLanguage.toUpperCase()}
        </div>
      ),
      icon: (
        <Icon icon='cds-ph-globe-simple' cdsColorToken='--cds-color-text-subtle' />
      ),
      popupOffset: [10, 0],
      popupClassName: `${styles.headerMenuChild}`,
      children: LANGUAGES.filter(
        (language) => language.value !== currentLanguage,
      ).map((language) => ({
        key: language.value,
        label: language.value.toUpperCase(),
        onClick: (selectedItem: { key: string }) =>
          i18n.changeLanguage(selectedItem.key),
      })),
    },
  ]
  return (
    <header className={styles.container}>
      {isMobile && <CircutorLargeIcon />}

      <div className={styles.menu}>
        <HeaderMenu items={menuItems} />
      </div>
    </header>
  )
}
