'use client'

import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import styles from './LayoutFooter.module.scss'

export default function LayoutFooter() {
  const { t, i18n } = useTranslation()
  const docLanguage = i18n.language === 'es' ? 'es' : 'en'

  return (
    <footer className={styles.container}>
      <div>
        <span>© Copyright 2024 CIRCUTOR</span>
        &nbsp;|&nbsp;
        <Link
          target='_blank'
          rel='noopener noreferrer'
          href={`https://terms.mycircutor.com/v1/privacy-policy-scout-${docLanguage}.html`}
        >
          {t('common.privacyPolicy')}
        </Link>
        &nbsp;|&nbsp;
        <Link
          target='_blank'
          rel='noopener noreferrer'
          href={`https://terms.mycircutor.com/v1/terms-scout-${docLanguage}.html`}
          className={styles.link}
        >
          {t('common.termsAndCondition')}
        </Link>
      </div>
    </footer>
  )
}
